export const extractEnvFromUrl = (): {
  tenant: string;
  deploymentEnv: string;
  isLocalhost: boolean;
} => {
  const { hostname } = window.location;
  const domains = hostname?.split('.');
  const domain = domains?.[0];
  const subdomain = domains?.[1];

  const isLocalhost = domain === 'localhost';
  const tenant = isLocalhost
    ? (process.env.TENANT as string)
    : // @ts-expect-error will not be undefiend
      domain?.match(/^(.*?)(?=-ui|-moderation-ui)/)[1];
  const deploymentEnv = isLocalhost
    ? 'pre'
    : subdomain?.split('-').slice(-1)?.[0];

  return { tenant, deploymentEnv, isLocalhost };
};
