import type { TenantName, DeploymentEnv } from './env/types';
import { extractEnvFromUrl } from '@utils/common/client';
import { getWidgetsEnv } from '@utils/common/env';

import packageInfo from '../package.json';

const init = async () => {
  const { tenant, deploymentEnv } = extractEnvFromUrl();

  const widgetsEnv = getWidgetsEnv();
  globalThis.__SERENITY_WIDGETS_ENV__ = widgetsEnv;
  globalThis.__SERENITY_TENANT__ = tenant as TenantName;
  globalThis.__SERENITY_DEPLOYMENT_ENV__ = deploymentEnv as DeploymentEnv;
  globalThis.__SERENITY_ENV__ = process?.env?.NODE_ENV as any;
  globalThis.__SERENITY_CYPRESS__ = process?.env?.IS_CYPRESS as any;
  globalThis.__SERENITY_IS_LOCAL_DEVELOPMENT =
    process?.env?.LOCAL_DEVELOPMENT === 'true';

  if (!__SERENITY_CYPRESS__) {
    if (__SERENITY_ENV__ === 'production') {
      const res = await fetch('/package.json', {
        method: 'GET',
        cache: 'no-store',
      });

      if (res.ok) {
        const packageInfo = await res.json();

        globalThis.__SERENITY_UI_VERSION__ = packageInfo?.version;

        console.info(`Current UI version: ${packageInfo?.version}`);
      }

      // Important to import datadog after resolving the version as
      // datadog need the version
      import('./datadog');
    } else if (__SERENITY_ENV__ === 'development') {
      globalThis.__SERENITY_UI_VERSION__ = packageInfo.version;
    } else {
      globalThis.__SERENITY_UI_VERSION__ = 'unknown';
    }
  }
};

init()
  .then(() => {
    import('./bootstrap');
  })
  .catch((err) => {
    console.error('Error while init the UI', err);
    // We still try to render the UI, only the package version might
    // have fail
    import('./bootstrap');
  });
